export const ORDER_STATUS = [
  { id: 0, name: "Pending" },
  { id: 1, name: "Completed" },
  { id: 2, name: "Processing" },
  { id: 3, name: "Failed" },
  { id: 4, name: "Cancelled" },
  { id: 5, name: "Insufficient balance" },
  { id: 6, name: "Refund" },
  { id: 7, name: "Submitted" },
  { id: 8, name: "Transaction received" },
  { id: 9, name: "Queued" },
  { id: 10, name: "Pending authorization" },
  { id: 11, name: "Rejected" },
  { id: 12, name: "Blocked" },
  { id: 13, name: "Insufficient balance completed" }
];
