<template>
  <div class="row align-items-center mb-5">
    <div class="col-12 col-md-4">
      <b-form-datepicker
        name="startCalendar"
        v-model="startDate"
        selected-variant="success"
        today-variant="info"
        nav-button-variant="primary"
        hide-header
        :locale="locale"
        :min="minDate"
        :max="maxDate"
        :disabled="datePickersDisabled"
        :placeholder="$t('PAGES.SALES_MANAGEMENT.LIST.SELECT_START_DATE')"
        @input="$emit('on-start-date-selected', startDate)"
      ></b-form-datepicker>
    </div>
    <div class="col-12 col-md-4">
      <b-form-datepicker
        name="endCalendar"
        v-model="endDate"
        selected-variant="success"
        today-variant="info"
        nav-button-variant="primary"
        hide-header
        :locale="locale"
        :min="minDate"
        :max="maxDate"
        :disabled="datePickersDisabled"
        :placeholder="$t('PAGES.SALES_MANAGEMENT.LIST.SELECT_END_DATE')"
        @input="$emit('on-end-date-selected', endDate)"
      ></b-form-datepicker>
    </div>
    <div class="col-auto">
      <b-form-checkbox
        v-model="allDates"
        value="true"
        unchecked-value="false"
        @input="onChecked"
      >
        {{ $t("PAGES.SALES_MANAGEMENT.LIST.ALL_DATES") }}
      </b-form-checkbox>
    </div>

    <div class="col-auto ml-auto">
      <slot name="filters"></slot>
    </div>
  </div>
</template>

<script>
import i18nService from "@/services/i18n.js";

export default {
  name: "DatePickers",
  props: ["filters"],
  data() {
    return {
      minDate: new Date("2021-01-01"),
      maxDate: new Date(),
      startDate: null,
      endDate: null,
      allDates: true,
      datePickersDisabled: true,
      locale: i18nService.getActiveLanguage()
    };
  },
  methods: {
    onChecked(checked) {
      const isChecked = checked === "true";
      this.datePickersDisabled = isChecked;
      if (isChecked) {
        this.startDate = null;
        this.endDate = null;
      }
      this.$emit("on-all-dates-selected", checked);
    }
  }
};
</script>
