import Api from "@/services/api";
import {
  getOrderBadgeAndLabel,
  getPaidCryptoQuantity,
  getPaymentUnitName,
  getPlatform
} from "@/utils/order-helpers";
import { paymentStateClass } from "@/constants";
import { ExtractRequestData } from "@/utils/extract-request";

export const ListMixin = {
  data() {
    return {
      isBusy: false,
      perPage: 0,
      listData: [],
      currentPage: 1,
      totalRows: 0,
      ordersCount: 0,
      ordersTotal: 0,
      filterIncludedFields: "",
      items: [],
      tableItems: [],
      filter: null,
      filters: [],
      showSearch: false,
      fields: [],
      startDate: null,
      endDate: null,
      exportPagination: "?pagination=false",
      include: [],
      transitionProps: {
        name: "flip-list"
      }
    };
  },
  computed: {
    optionFilters() {
      return this.filters.filter(filter => filter.currentValue !== undefined);
    },
    listTable() {
      return this.$refs.listTable;
    }
  },
  methods: {
    async fetchData(context) {
      const { currentPage, filter, sortBy, sortDesc } = context;
      this.currenctPage = currentPage;
      const includeLength = this.include.length;
      let optionParams = "";
      let filterParams = "";
      let sortParams = "";
      let dateParams = "";
      let includeParams = "";
      let sortDirection = sortDesc ? "desc" : "asc";

      this.optionFilters.forEach(optionFilter => {
        optionParams += `&${optionFilter.field}=${optionFilter.currentValue}`;
      });

      if (this.startDate) {
        dateParams += `&createdAt[after]=${this.startDate}`;
      }

      if (this.endDate) {
        dateParams += `&createdAt[before]=${this.endDate}`;
      }

      if (filter) {
        /* this.filterIncludedFields.forEach(filterIncludedField => {
          filterParams += `&${filterIncludedField}=${this.filter}`;
        }); */
        filterParams += `&${this.filterIncludedFields}=${this.filter}`;
      }

      const sortById = sortBy === "_id" ? "id" : sortBy;

      if (sortById) {
        sortParams += `&order[${sortById}]=${sortDirection}`;
      }

      if (includeLength) {
        includeParams = "&include=";
        this.include.forEach((includeItem, index) => {
          includeParams += `${includeItem}`;

          if (index + 1 < includeLength) {
            includeParams += ",";
          }
        });
      }

      const fixedFilters = this.fixedFilters ? `&${this.fixedFilters}` : "";

      const pageParam = `?page=${currentPage}`;
      const queryParams =
        pageParam +
        includeParams +
        filterParams +
        fixedFilters +
        optionParams +
        sortParams +
        dateParams;

      const fetchedData = await Api.get(`${this.fetchUrl}/${queryParams}`, {
        headers: { Accept: "application/vnd.api+json" }
      });

      const { data, totalItems, itemsPerPage } = ExtractRequestData(
        fetchedData
      );

      this.listData = data;
      this.totalRows = totalItems;
      this.perPage = itemsPerPage;
      window.scrollTo(0, 0);
      return data;
    },
    async generatePDF() {
      try {
        const fixedFilters = this.fixedFilters ? `&${this.fixedFilters}` : "";
        const params = this.exportPagination + fixedFilters;
        const fetchedData = await Api.get(`${this.fetchUrl}/${params}`);

        this.generatingPdf = true;
        this.exportItems = fetchedData.data;
        this.$refs.html2Pdf.generatePdf();
      } catch (error) {
        this.$root.$bvToast.toast(this.$t("COMMON.NO_DATA_AVAILABLE"), {
          title: this.$t("COMMON.INFORM"),
          variant: "warning",
          autoHideDelay: 5000
        });
        this.exportItems = [];
      }
    },
    onProgress(event) {
      this.generatingPdf = event < 100;
    },
    async generateExcel(mimeType = "xls") {
      this.excelType = mimeType;

      const excelFields = {};
      this.fields.forEach(val => {
        excelFields[val.label] = val.key === "_id" ? "id" : val.key;
      });
      this.excelFields = excelFields;
      const fixedFilters = this.fixedFilters ? `&${this.fixedFilters}` : "";
      const params = this.exportPagination + fixedFilters;
      const exportData = await Api.get(`${this.fetchUrl}/${params}`);

      const data = exportData.data;

      if (data.length > 0) {
        data.forEach(item => {
          if (item.createdAt) {
            // item.createdAt = this.$moment(item.createdAt).format("DD.MM.YYYY");
            item.createdAt = this.$dateTimeFilter(item.createdAt);
          }

          if (`${item.status}`.length > 0) {
            item.orderStatus = getOrderBadgeAndLabel(item.status).itemLabel;
          }

          if (`${item.paymentStateId}`.length > 0) {
            item.paymentStateName = this.$t(
              `PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATES[${item.paymentStateId}]`
            );
          }
          if (`${item.platform}`.length > 0) {
            item.platform = getPlatform(item);
          }
          item.nexusAmount = this.getPaidCryptoQuantity(item);
        });

        return data;
      }

      this.generatingPdf = false;
      this.$root.$bvToast.toast(this.$t("COMMON.NO_DATA_AVAILABLE"), {
        title: this.$t("COMMON.INFORM"),
        variant: "warning",
        autoHideDelay: 5000
      });
    },
    onOptionSelected(filterIndex, value) {
      this.filters[filterIndex].currentValue = value;
      this.$refs.listTable.refresh();
    },
    onFilterChange(filter) {
      this.filter = filter;
    },
    onAllDatesSelected(checked) {
      const hasStartDate = this.startDate !== null;
      const hasEndDate = this.endDate !== null;

      const hasAnyDate = hasStartDate || hasEndDate;

      if (checked === "true" && hasAnyDate) {
        this.startDate = null;
        this.endDate = null;
        this.$refs.listTable.refresh();
      }
    },
    onStartDateSelected(startDate) {
      this.startDate = startDate;
      this.$refs.listTable.refresh();
    },
    onEndDateSelected(endDate) {
      this.endDate = endDate;
      this.$refs.listTable.refresh();
    },
    onRowSelected(/* item */) {},
    onRefreshed() {},
    getPaidCryptoQuantity: getPaidCryptoQuantity,
    paymentStateClass: paymentStateClass,
    getOrderBadgeAndLabel: getOrderBadgeAndLabel,
    getPaymentUnitName: getPaymentUnitName,
    getPlatform: getPlatform
  }
};
