import { getOrderPaymentStateBadge } from "../utils/order-helpers";

export const PAYMENT_STATE = [
  { id: 1, name: "onstandby" },
  { id: 2, name: "processed" },
  { id: 3, name: "completed" },
  { id: 4, name: "canceled" }
];

export const paymentStateClass = stateId => {
  const state = PAYMENT_STATE[stateId - 1];
  return getOrderPaymentStateBadge(state.id - 1);
};
