const camelise = value => {
  value = value.toString();
  return value.charAt(0).toLowerCase() + value.slice(1);
};

const extractUri = (relationshipData, relationshipKey = null) => {
  const relationshipDataPart = relationshipData.data ?? relationshipData.type;
  const relationshipDataType = relationshipKey ?? relationshipDataPart;

  return {
    attributeName: `${camelise(relationshipDataType)}Uri`,
    attributeValue: relationshipData.id.substring(5)
  };
};

export const ExtractRequestData = response => {
  const data = response.data.data.map(item => {
    const attributes = item.attributes;
    const relationships = item.relationships;

    if (relationships) {
      Object.values(relationships).forEach((relationship, index) => {
        const relationshipData = relationship.data;
        const relationshipKey = Object.keys(relationships)[index];

        if (relationshipData.length) {
          let relationshipArray = [];

          relationshipData.forEach(arrayData => {
            const { attributeName, attributeValue } = extractUri(arrayData);
            relationshipArray.push({ [attributeName]: attributeValue });
          });
          attributes[relationshipKey] = relationshipArray;
        } else {
          const { attributeName, attributeValue } = extractUri(
            relationshipData,
            relationshipKey
          );
          attributes[attributeName] = attributeValue;
        }
      });
    }

    return attributes;
  });

  const totalItems = response.data.meta.totalItems;
  const itemsPerPage = response.data.meta.itemsPerPage;

  let totalPages = parseInt(totalItems / itemsPerPage);
  const remainder = totalItems % itemsPerPage;

  totalPages = remainder ? totalPages + 1 : totalPages;

  return { data, totalItems, itemsPerPage, totalPages };
};
